import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I love writing code to solve real world problems. I also love `}<i>{`The Office`}</i>{` TV series.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Sometimes I'll start a sentence and I don't even know where it's going. I just hope I find it along the way.  - Michael Scott`}</p>
    </blockquote>
    <p>{`This is my humble abode online for fore-see-able future.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      